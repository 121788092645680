import { useLocation } from "@reach/router"
import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"

import ErrorIcon from "../../static/icons/alert/icon-alert-danger.svg"
import InfoIcon from "../../static/icons/alert/icon-alert-info.svg"
import SuccessIcon from "../../static/icons/alert/icon-alert-success.svg"
import WarningIcon from "../../static/icons/alert/icon-alert-warning.svg"

const NOTIFICATION_LIFETIME = 2000

const autoHideDuration = {
  success: 2000,
  warning: 4000,
  error: 6000,
  info: 3000,
}

const Root = styled.div`
  box-shadow: 0px 5px 20px 0px ${({ theme }) => `${theme.colors.black}50`};
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  opacity: 1;

  &.fading {
    transition: opacity 2s;
    opacity: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.screens.sm}) {
    padding: 8px ${({ theme }) => theme.spacing["gutter-sm"]};
  }

  @media screen and (min-width: ${({ theme }) => theme.screens.md}) {
    padding: 8px ${({ theme }) => theme.spacing["gutter-md"]};
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .message {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      height: 38px;
    }

    h6 {
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 500;
`

interface Props {
  type: string
  message?: string
  title: string
  onClose?: () => void
}

const Toast: React.FC<Props> = ({ type, message, title, onClose }) => {
  const [hide, setHide] = useState(false)
  const { pathname } = useLocation()
  const locationWithError = useRef(pathname)

  const backgroundColor =
    type === "success"
      ? "bg-toast-success"
      : type === "danger"
        ? "bg-toast-danger"
        : type === "warning"
          ? "bg-toast-warning"
          : "bg-toast-info"

  const toastIconPath =
    type === "success"
      ? SuccessIcon
      : type === "danger"
        ? ErrorIcon
        : type === "warning"
          ? WarningIcon
          : InfoIcon

  const visibilityTimeout =
    type === "success"
      ? autoHideDuration.success
      : type === "danger"
        ? autoHideDuration.error
        : type === "warning"
          ? autoHideDuration.warning
          : autoHideDuration.info

  const notificationRef = useRef<HTMLDivElement>(null)

  const disappearTimerRef = useRef<number | undefined>()

  const disappear = useCallback((timestamp: number) => {
    if (!disappearTimerRef.current) {
      disappearTimerRef.current = timestamp
    }
    if (timestamp - disappearTimerRef.current >= NOTIFICATION_LIFETIME) {
      disappearTimerRef.current = undefined
      if (
        (notificationRef.current?.style.opacity == "0" ||
          notificationRef.current?.style.opacity) == "" &&
        onClose
      ) {
        onClose()
      }
    } else {
      requestAnimationFrame(disappear)
    }
  }, [])

  const timeoutTimerRef = useRef<number | undefined>()

  const timeout = useCallback(
    (timestamp: number) => {
      if (!timeoutTimerRef.current) {
        timeoutTimerRef.current = timestamp
      }
      if (
        timestamp - timeoutTimerRef.current >= visibilityTimeout &&
        timeoutTimerRef !== undefined
      ) {
        timeoutTimerRef.current = undefined
        disappearTimerRef.current = undefined

        setHide(true)
        requestAnimationFrame(disappear)
      } else {
        requestAnimationFrame(timeout)
      }
    },
    [disappear, visibilityTimeout]
  )

  const reset = useCallback(() => {
    timeoutTimerRef.current = undefined
    disappearTimerRef.current = undefined
    setHide(false)
  }, [])

  useEffect(() => {
    if (locationWithError.current !== pathname) {
      setHide(true)
    } else {
      reset()
      requestAnimationFrame(timeout)
    }

    return () => {
      setHide(false)
    }
  }, [type, reset, timeout, pathname])

  return (
    <Root
      ref={notificationRef}
      className={classNames({ fading: hide }, backgroundColor)}
    >
      <Content>
        <div className="message" onClick={onClose}>
          <img src={toastIconPath} alt="toast icon" />
          <h6>
            {title}
            {message && message !== "" ? `, ${message}` : null}
          </h6>
        </div>
        <CloseButton onClick={onClose}>HIDE</CloseButton>
      </Content>
    </Root>
  )
}

export default Toast
