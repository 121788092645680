import classNames from "classnames"
import React, { ReactNode, useCallback, useMemo } from "react"

import { Color } from "../../../../constants/V2/color"
import { trackSegmentEvent } from "../../../../utils/analytics"
import { useIsBreakpoint } from "../../../../utils/V2/screen"
import Link from "../../Link"
import Arrow from "../Arrow"
import Typography from "../Typography"

import {
  getBorderColorClass,
  getBackgroundColorClass,
  getBackgroundHoverColorClass,
  getAccentColor,
  getTextColorClass,
  getTextHoverColorClass,
} from "@utils/V2/color"

type Props = {
  style: "solid" | "bordered"
  size: "large" | "medium" | "small"
  type?: "button" | "submit"
  color: Color
  overrideTextColor?: Color
  text: string
  mobileText?: string
  linkUrl?: string
  mobileLinkUrl?: string
  onClick?: () => void
  hideIcon?: boolean
  icon?: ReactNode
  iconPosition?: "left" | "right"
  disabled?: boolean
  trackingEvent?: string
  trackingEventKey?: string
  trackingEventValue?: string
  className?: string
  nonResponsive?: boolean
}

const PillButton: React.FC<Props> = ({
  style,
  size = "large",
  type = "button",
  color,
  overrideTextColor = getAccentColor(color),
  text,
  mobileText,
  linkUrl,
  mobileLinkUrl,
  onClick,
  hideIcon = false,
  icon = <Arrow />,
  iconPosition = "right",
  disabled = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  className,
  nonResponsive: nonResponsiveProp = false,
}) => {
  const isMobile = useIsBreakpoint("mobile")
  const nonResponsive = isMobile || nonResponsiveProp

  const sizeOverride = nonResponsive ? "medium" : size

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        const event =
          trackingEventKey && trackingEventValue
            ? { [trackingEventKey]: trackingEventValue }
            : undefined
        trackSegmentEvent(trackingEvent, event)
      }

      if (onClick) {
        onClick()
      } else if (link && link.startsWith("#")) {
        e.preventDefault()
        const element = document.querySelector(link)
        if (element) {
          element.scrollIntoView({ behavior: "smooth" })
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  )

  const buttonClasses = useMemo(
    () =>
      classNames(
        getBorderColorClass(color),
        style === "solid"
          ? getBackgroundColorClass(color)
          : [
              "bg-transparent transition-colors duration-300",
              getBackgroundHoverColorClass(color),
            ],
        { "opacity-50 pointer-events-none": disabled },
        "group rounded-32-v2 border flex gap-10-v2 items-center w-full sm-v2:w-max justify-center",
        {
          "px-16-v2 py-12-v2 md-v2:px-[18px] md-v2:py-[14px] lg-v2:px-20-v2 lg-v2:py-16-v2":
            ["large", "medium"].includes(sizeOverride) && !nonResponsive,
        },
        {
          "px-20-v2 py-16-v2":
            ["large", "medium"].includes(sizeOverride) && nonResponsive,
        },
        {
          "px-16-v2 py-10-v2": size === "small",
        },
        { "flex-row-reverse": iconPosition === "left" },
        className
      ),
    [color, style, disabled, sizeOverride, isMobile, className, size]
  )

  const buttonContentClasses = classNames(
    "!leading-none transition-colors duration-300",
    style === "bordered" && getTextHoverColorClass(getAccentColor(color))
  )

  const buttonContent = (
    <>
      {mobileText ? (
        <Typography
          text={isMobile ? mobileText : text}
          weight="medium"
          font="grotesk"
          color={overrideTextColor}
          size="body-lg"
          className={buttonContentClasses}
          nonResponsive={nonResponsive}
        />
      ) : (
        <Typography
          text={text}
          weight="medium"
          font="grotesk"
          color={overrideTextColor}
          size={["small", "medium"].includes(size) ? "body-md" : "body-lg"}
          className={buttonContentClasses}
          nonResponsive={nonResponsive}
        />
      )}

      {!hideIcon && (
        <div
          className={classNames(
            getTextColorClass(overrideTextColor),
            "w-20-v2 md-v2:w-[22px] lg-v2:w-24-v2",
            buttonContentClasses
          )}
        >
          {icon}
        </div>
      )}
    </>
  )

  if (linkUrl && mobileLinkUrl) {
    return (
      <Link
        link={isMobile ? mobileLinkUrl : linkUrl}
        onClick={(e) => handleClick(e, isMobile ? mobileLinkUrl : linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  } else if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        link={linkUrl}
        onClick={(e) => handleClick(e, linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  }

  return (
    <button
      type={type}
      onClick={(e) => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
    >
      {buttonContent}
    </button>
  )
}

export default PillButton
