import classNames from "classnames"
import React, { useState } from "react"

import { Color } from "../../../constants/V2/color"
import TextButton from "../../elements/V2/Buttons/TextButton"
import CloseIcon from "../../elements/V2/CloseIcon"
import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../elements/V2/Typography"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  backgroundColor: Color
  textColor: Color
  icon: string
  text: string
  ctaText: string
  ctaLink: string
}

const ToastNotification: React.FC<Props> = ({
  backgroundColor,
  textColor,
  icon,
  text,
  ctaText,
  ctaLink,
}) => {
  const [isClosed, setIsClosed] = useState(false)

  return (
    <div
      className={classNames(
        "flex h-60-v2 items-center justify-between overflow-hidden px-gutter-xs-v2 py-12-v2 md-v2:h-[50px] md-v2:px-gutter-md-v2 lg-v2:px-gutter-lg-v2",
        getBackgroundColorClass(backgroundColor),
        { hidden: isClosed }
      )}
    >
      <div className="flex items-center">
        <GatsbyStoryblokImage
          image={icon}
          alt="banner icon"
          className="mr-10-v2"
          width={24}
        />
        <Typography
          font="grotesk"
          weight="book"
          size="body-sm"
          color={textColor}
          text={text}
          className="mr-4-v2 md-v2:whitespace-nowrap"
          element="p"
        />
        <TextButton
          style="text"
          textSize="body-sm"
          color={textColor}
          text={ctaText}
          className="-mb-4-v2 flex items-center justify-center"
          linkUrl={ctaLink}
        />
      </div>
      <div className="h-24-v2 w-24-v2 cursor-pointer">
        <CloseIcon
          width="24px"
          height="24px"
          hideBorder
          strokeWidth="2"
          strokeColor={textColor}
          onClick={() => setIsClosed(true)}
        />
      </div>
    </div>
  )
}

export default ToastNotification
